<template>
  <v-app id="cs">
    <v-app-bar app flat color="white">
      <v-container class="py-0 fill-height">
        <router-link to="/" class="text-decoration-none title">
          <v-toolbar-title data-cy="siteTitle">climateSpy.com</v-toolbar-title>
        </router-link>

        <v-spacer></v-spacer>

        <v-responsive max-width="260">
          <v-text-field
              dense
              flat
              hide-details
              rounded
              solo-inverted
          ></v-text-field>
        </v-responsive>
      </v-container>
    </v-app-bar>
    <v-main class="blue lighten-5">
      <v-container>
        <v-row>
          <v-col>
            <BreadCrumb :station-id="$route.params.stationId"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9">
            <v-container>
              <router-view/>
            </v-container>
          </v-col>
          <v-col cols="3">
            <stationSidebar :station-id="$route.params.stationId"/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>

import StationSidebar from '@/components/stationSidebar'
import BreadCrumb from '@/components/breadCrumb'
// import { onMounted} from "@vue/composition-api";

export default {
  components: {BreadCrumb, StationSidebar},
}
</script>
<style lang="scss">
</style>
