<template>
  <apexchart height="350" type="scatter" :options="options" :series="series"></apexchart>
</template>

<script>
export default {
  name: "hottestDayOfYear",
  data: function () {
    return {
      options: {
        chart: {
          id: 'vuechart-example',
          type: 'scatter'

        },
      },
      xaxis: {
        type: 'datetime',
      },
      series: [
        {
          name: '2020\'s',
          type: 'scatter',
          data: [{
            x: new Date(2021, 7, 8),
            y: 102,
          }]
        },
        {
          name: '2010\'s',
          type: 'scatter',
          data: [
            {
              x: new Date(2020, 8, 5),
              y: 100.9
            },
            {
              x: new Date(2019, 6, 19),
              y: 100.9
            },
            {
              x: new Date(2018, 5, 28),
              y: 105.1
            },
            {
              x: new Date(2017, 6, 6),
              y: 100
            },
            {
              x: new Date(2016, 6, 10),
              y: 102
            },
            {
              x: new Date(2015, 7, 15),
              y: 98.1
            }]
        },
        {
          name: '2000\'s',
          type: 'scatter',
          data: [
            {
              x: new Date(2020, 8, 15),
              y: 102.9
            },
            {
              x: new Date(2019, 6, 19),
              y: 100.9
            },
            {
              x: new Date(2018, 5, 28),
              y: 105.1
            },
            {
              x: new Date(2017, 6, 6),
              y: 100
            },
            {
              x: new Date(2016, 6, 10),
              y: 102
            },
            {
              x: new Date(2015, 7, 15),
              y: 98.1
            }]
        },

      ]
    }
  },
}
</script>

<style scoped>

</style>