<template>

  <v-card v-if="isLoaded">
    <v-card-title>Preciption by Year</v-card-title>
    <v-card-subtitle>Total precipitation year by year</v-card-subtitle>
    <v-card-text>
      <v-responsive>
        <apexchart :options="chartOptions()" :series="chartSeries()" height="100"></apexchart>
      </v-responsive>
    </v-card-text>
  </v-card>

</template>

<script>
import useGSOD from '@/composable/useGSOD'

export default {
  name: "yearlyPrecipitationSparkline.vue",
  props: {
    stationId: {
      type: String,
    },
  },

  setup(props) {
    const {isLoaded, annualAggregates} = useGSOD(props.stationId)

    function chartOptions() {
      return {
        chart: {
          type: 'bar',
          sparkline: {
            enabled: true,
          },
          toolbar: {
            show: false,
          }

        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        dataLabels: {
          enabled: false
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value + "F";
            }
          },
        },
        tooltip: {
          y: {
            formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
              console.log({series}, {seriesIndex}, {dataPointIndex}, {w}, {value})
              return value
            }
          }
        }
      }
    }

    function chartSeries() {
      let series = annualAggregates().map((r) => {

        return {
          x: r.YEAR,
          y: r.PRCP_sum
        }
      })
      return [{
        name: 'Annual Precipitation',
        data: series
      }]
    }

    return {
      isLoaded,
      chartOptions,
      chartSeries
    }
  },


}
</script>

<style scoped>

</style>