<template>
  <v-card color="transparent" flat v-if="loaded">
    <v-card-text class="ma-0 pa-0">
      <v-breadcrumbs divider="/">
        <v-breadcrumbs-item to="/">Home</v-breadcrumbs-item>
        <v-breadcrumbs-item to="/stations/US">United States</v-breadcrumbs-item>
        <v-breadcrumbs-item :to="new String().concat('/stations/US/', stationDetails(stationId)['STATE'])">
          {{ stationDetails(stationId)['stateName'] }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-item :to="new String().concat('/climate/',stationId)">
          {{ stationDetails(stationId)["STATION NAME"] }}
        </v-breadcrumbs-item>
      </v-breadcrumbs>
    </v-card-text>
    <v-card-title>Climate summary for {{ stationDetails(stationId)["STATION NAME"] }}</v-card-title>
  </v-card>

</template>

<script>
import useStations from '@/composition/useStations'

export default {
  name: "breadCrumb.vue",
  props: {
    stationId: {
      type: String
    },
  },
  setup() {
    const {stationDetails, stationList, stateList, loaded} = useStations()

    return {
      stationDetails, stationList, stateList, loaded
    }
  }
}
</script>

<style scoped>

</style>