<template>
  <div v-if="isLoaded">
    <v-row>
      <v-col>
        <v-card className="transparent" flat>
          <v-card-title>Precipitation Records for {{ stationName }}</v-card-title>
          <v-card-subtitle>From: <span data-cy="fromDate">{{ fromDate.toLocaleDateString() }}</span>, To: <span
              data-cy="toDate">{{ toDate.toLocaleDateString() }}</span>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card elevation="2" data-cy="hottestRecord" flat>
          <v-card-title>Wettest Days</v-card-title>
          <v-card-subtitle>The 10 wettest days on record at {{ stationName }} are:</v-card-subtitle>
          <v-card-text>
            <v-simple-table dense>
              <tbody data-cy="wettestDayTable">
              <tr v-for="(row,idx) in wettestDays(10)" :key="idx">
                <td class="text-center">{{ row.DATE.toLocaleDateString() }}</td>
                <td class="text-center">{{ row.PRCP }} inches</td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card elevation="2" data-cy="hottestRecord" flat>
          <v-card-title>Wettest Day by Year</v-card-title>
          <v-card-subtitle>The wettest day by year for the last 10 years are:</v-card-subtitle>
          <v-card-text>
            <v-simple-table dense>
              <tbody data-cy="hottestDayTable">
              <tr v-for="(row,idx) in wettestDayOfYear(10)" :key="idx">
                <td class="text-center">{{ row.DATE.toLocaleDateString() }}</td>
                <td class="text-center">{{ row.PRCP }} inches</td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card elecvation="2" data-cy="hottestDayChart" flat>
          <v-card-title>
            Hottest Day of the Year
          </v-card-title>
          <v-card-text>
            <hottest-day-of-year/>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>

// import {loadStationGSOD, getHottestDates} from '@/composable/climateDataService'
import useGSOD from '@/composable/useGSOD'
import hottestDayOfYear from "@/components/charts/hottestDayOfYear";

export default {
  name: "Wettest.vue",
  components: {
    hottestDayOfYear
  },
  props: {
    stationId: {
      type: String,
    },
  },
  setup(props) {
    const {isLoaded, fromDate, toDate, stationName, wettestDays, wettestDayOfYear} = useGSOD(props.stationId)

    return {
      isLoaded,
      fromDate,
      toDate,
      stationName,
      wettestDays,
      wettestDayOfYear,
    }
  }
}
</script>

<style scoped>

</style>