<template>
  <v-row v-if="isLoaded">
    <v-col>
      <v-card flat v-if="stateCode === undefined">
        <v-card-title>State List</v-card-title>
        <v-card-text>
          <ul v-for="state in getStateList()" v-bind:key="state.stateName">
            <li>
              <router-link :to="{ name:'stateStations', params: { stateCode: state.STATE}}">{{ state.stateName }}
              </router-link>
            </li>
          </ul>
        </v-card-text>
      </v-card>
      <v-card flat v-else>
        <v-card-title>Climate Locations for {{ stateCode }}</v-card-title>
        <v-card-text>
          <ul v-for="station in getStationList(stateCode)" v-bind:key="station.stationId">
            <li>
              <router-link :to="{ name:'Climate', params: { stationId: station.stationId}}">
                {{ station['STATION NAME'] }}
              </router-link>
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import useStations from '@/composable/useStations'


export default {
  name: 'Home',
  props: {
    stateCode: {
      type: String,
    },
  },
  setup() {
    console.log('stations')
    const {getStateList, getStationList, isLoaded} = useStations()


    return {
      getStateList,
      getStationList,
      isLoaded
    }
  }

}
</script>
