<template>
  <v-card elevation="1" flat v-if="loaded">
    <v-card-text>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title>
            {{ stationDetails(stationId)['STATION NAME'] }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-row>
              <v-col>
                <dl>
                  <dt>Lat</dt>
                  <dd>{{ stationDetails(stationId)['LAT'] }}</dd>
                </dl>
              </v-col>
              <v-col>
                <dl>
                  <dt>Long</dt>
                  <dd>{{ stationDetails(stationId)['LON'] }}</dd>
                </dl>
              </v-col>
              <v-col>
                <dl>
                  <dt>Elevation</dt>
                  {{ stationDetails(stationId)['ELEV(M)'] }} m
                </dl>

              </v-col>
            </v-row>
          </v-list-item-subtitle>

          <v-list-item-subtitle>
            <v-row>
              <v-col>
                <dl>
                  <dt>From</dt>
                  <dd>{{ stationDetails(stationId)['BEGIN'].toLocaleDateString() }}</dd>
                </dl>
              </v-col>
              <v-col>
                <dl>
                  <dt>To</dt>
                  <dd>{{ stationDetails(stationId)['END'].toLocaleDateString() }}</dd>
                </dl>
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-3"/>
      <v-list color="transparent">
        <v-list-item :to="{name:'Climate'}" exact>
          Climate Summary
        </v-list-item>
        <v-list-item :to="{name:'Hottest'}" exact>Heat Records</v-list-item>
        <v-list-item :to="{name:'Coldest'}" exact>Cold Records</v-list-item>
        <v-list-item :to="{name:'Precipitation'}" exact>Precipitation Records</v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import useStations from '@/composition/useStations'

export default {
  name: "stationSidebar.vue",
  props: {
    stationId: {
      type: String
    },
  },
  setup() {
    const {stationDetails, stationList, stateList, loaded} = useStations()

    return {
      stationDetails, stationList, stateList, loaded
    }
  }
}
</script>

<style scoped>

</style>