<template>
  <div v-if="isLoaded">
    <v-row>
      <v-col>
      </v-col>
    </v-row>
    <recordCards :coldest-days="coldestDays(1)" :hottest-days="hottestDays(1)" :wettest-days="wettestDays(1)"/>
    <v-row>
      <v-col cols="12">
        <MonthlyChart :stationId="stationId"></MonthlyChart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <yearly-temperature-range-sparkline :stationId="stationId"/>
      </v-col>
      <v-col cols="6">
        <yearly-precipitation-sparkline :stationId="stationId"/>
      </v-col>

    </v-row>
    <v-row>
      <v-col>
        The climate summary for this page is based on data from the Global Summary of the Day and uses daily weather
        observations between {{ fromDate.toLocaleDateString() }} and {{ toDate.toLocaleDateString() }} at
        {{ stationName }}.
      </v-col>
    </v-row>
  </div>
</template>

<script>
/**
 * TODO - Make temperature summary chart clickable
 * TODO - Add precipitation chart - rainfall and number of days rain
 * TODO - add narrative to temperature range by year
 * TODO - add narrative to precipitation by year
 */
// import {loadStationGSOD, getHottestDates} from '@/composable/climateDataService'
import useGSOD from '@/composable/useGSOD'
import MonthlyChart from '@/components/charts/monthlyChart'
import yearlyTemperatureRangeSparkline from "@/components/charts/yearlyTemperatureRangeSparkline";
import yearlyPrecipitationSparkline from "@/components/charts/yearlyPrecipitationSparkline";
import RecordCards from "@/components/cards/RecordCards";

export default {
  name: "Climate.vue",
  components: {RecordCards, MonthlyChart, yearlyTemperatureRangeSparkline, yearlyPrecipitationSparkline},
  props: {
    stationId: {
      type: String,
    },
  },
  setup(props) {
    const {
      isLoaded,
      fromDate,
      toDate,
      stationName,
      hottestDays,
      coldestDays,
      wettestDays,
      annualAggregates
    } = useGSOD(props.stationId)


    return {
      isLoaded,
      fromDate,
      toDate,
      stationName,
      hottestDays,
      coldestDays,
      wettestDays,
      annualAggregates
    }
  }
}
</script>

