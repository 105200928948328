<template>
  <div v-if="isLoaded">
    <v-row>
      <v-col>The warmest month is {{ monthName[(hottestMonths(1).MONTH)] }} with an average
        high temperature of {{ Math.round(hottestMonths(1)['MAX_mean']) }}&deg;F.
        The hottest day on record was {{
          hottestDays(1).DATE.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })
        }}

        when the temperature hit {{ hottestDays(1).MAX }}°F.
        During {{ monthName[(coldestMonths(1).MONTH)] }} the overnight temperature drops to an average of
        {{ Math.round(coldestMonths(1)['MIN_mean']) }}°F with the lowest temperature of {{ coldestDays(1).MIN }}°F being
        recorded on
        {{
          coldestDays(1).DATE.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })
        }}.
      </v-col>
    </v-row>
  </div>
</template>

<script>
import useGSOD from "@/composable/useGSOD";

export default {
  name: "StationSummary",

  setup() {
    const {isLoaded, coldestDays, hottestDays, coldestMonths, hottestMonths, stationName, monthName} = useGSOD()

    return {
      coldestDays,
      coldestMonths,
      hottestDays,
      hottestMonths,
      isLoaded,
      stationName,
      monthName
    }
  }
}
</script>

<style scoped>

</style>