import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import About from '../views/About'
import Climate from '../views/Climate'
import Hottest from '../views/Hottest'
import Coldest from '../views/Coldest'
import Precipitation from '../views/Precipitation'
import Stations from '../views/Stations'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/stations'
  },
  {
    path: '/stations/US/:stateCode',
    name: 'stateStations',
    component: Stations,
    props: true,
  },
  {
    path: '/stations/US',
    name: 'USStates',
    component: Stations,
  },

  {
    path: '/stations',
    name: 'Stations',
    component: Stations
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About
  },
  {
    path: '/climate/:stationId/hottest',
    name: 'Hottest',
    component: Hottest,
    props: true
  },
  {
    path: '/climate/:stationId/coldest',
    name: 'Coldest',
    component: Coldest,
    props: true,
  },
  {
    path: '/climate/:stationId/precipitation',
    name: 'Precipitation',
    component: Precipitation,
    props: true,
  },
  {
    path: '/climate/:stationId',
    name: 'Climate',
    component: Climate,
    props: true
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
