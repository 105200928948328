<template>
  <v-card v-if="isLoaded">
    <v-card-title>Average Monthly Temperature Range
      <v-spacer/>
      <v-btn-toggle v-model="displayOption" mandatory>
        <v-btn icon>
          <v-icon>mdi-chart-histogram</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-table</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-card-subtitle>High/low average temperature by month</v-card-subtitle>
    <v-card-text>
      <apexchart type="rangeBar" :options="chartOptions()" :series="chartSeries()" height="auto"
                 v-if="displayOption === 0"></apexchart>
      <v-simple-table v-if="displayOption === 1" dense>
        <thead>
        <th>Month</th>
        <th>Average Low</th>
        <th>Average High</th>
        </thead>
        <tbody data-cy="averageMonthlyTemperature">
        <tr v-for="(row,idx) in monthlyAggregates()" :key="idx">
          <td class="text-center">{{ monthName[row["MONTH"]] }}</td>
          <td class="text-center">{{ Math.round(row["MIN_mean"]) }}&deg;F</td>
          <td class="text-center">{{ Math.round(row["MAX_mean"]) }}&deg;F</td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <StationSummary/>
    </v-card-actions>
  </v-card>

</template>

<script>
import useGSOD from '@/composable/useGSOD'
import StationSummary from "@/components/narrative/stationSummary";

export default {
  name: "monthlyChart.vue",
  components: {StationSummary},
  props: {
    stationId: {
      type: String,
    },
  },
  data() {
    return {
      displayOption: 0
    }
  },
  setup(props) {
    const {isLoaded, monthlyAggregates, monthName} = useGSOD(props.stationId)

    function chartOptions() {
      return {
        chart: {
          type: 'rangebar',
          toolbar: {
            show: false,
          },
        },
        colors: ['#fdbb84'],
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        dataLabels: {
          enabled: false
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value + "F";
            }
          },
        },
        tooltip: {
          y: {
            formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
              console.log({series}, {seriesIndex}, {dataPointIndex}, {w}, {value})
              return value

            }
          }
        }
      }
    }

    function chartSeries() {
      let series = monthlyAggregates().map((r) => {
        return {
          x: monthName[r.MONTH],
          y: [r.MIN_mean, r.MAX_mean]
        }
      })
      return [{
        data: series
      }]
    }

    return {
      isLoaded,
      monthlyAggregates,
      chartOptions,
      chartSeries,
      monthName
    }
  },


}
</script>

<style scoped>

</style>