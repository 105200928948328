import {loadArrow, op, desc} from "arquero";
import {ref, onMounted} from '@vue/composition-api'

export default function useGSOD(stationId) {
    console.log('useGSOD', stationId)
    var dt = ref(null)
    const monthName = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ]

    /* list of coldest days */
    const coldestDays = (numDays) => {
        console.log('coldestDays', numDays)
        try {
            let temp = dt.value
            if (numDays > 1) {
                /* istanbul ignore next */
                return temp
                    .filter(d => d.MIN !== null)
                    .orderby('MIN')
                    .slice(0, numDays)
                    .objects()
            } else {
                /* istanbul ignore next */
                return temp
                    .filter(d => d.MIN !== null)
                    .orderby('MIN')
                    .slice(0, numDays)
                    .object()
            }

        } catch (error) {
            console.error(error)
        }
    }


    /* list of hottest days */
    const hottestDays = (numDays) => {
        console.log('hottestDays', numDays)
        try {
            let temp = dt.value
            if (numDays > 1) {
                return temp
                    .orderby(desc('MAX'))
                    .slice(0, numDays)
                    .objects()
            } else {
                return temp
                    .orderby(desc('MAX'))
                    .slice(0, numDays)
                    .object()
            }

        } catch (error) {
            console.error(error)
        }
    }

    /* list of wettest days */
    const wettestDays = (numDays) => {
        console.log('hottestDays', numDays)
        try {
            let temp = dt.value
            if (numDays > 1) {
                return temp
                    .orderby(desc('PRCP'))
                    .slice(0, numDays)
                    .objects()
            } else {
                return temp
                    .orderby(desc('PRCP'))
                    .slice(0, numDays)
                    .object()
            }

        } catch (error) {
            console.error(error)
        }
    }

    /* list of hottest months */
    const hottestMonths = (numMonths) => {
        console.log('hottestMonths', numMonths)
        try {
            let temp = dt.value
            if (numMonths > 1) {
                return temp
                    .groupby('MONTH')
                    .rollup({
                        MAX_mean: op.mean('MAX')
                    })
                    .orderby(desc('MAX_mean'))
                    .slice(0, numMonths)
                    .objects()
            } else {
                return temp
                    .groupby('MONTH')
                    .rollup({
                        MAX_mean: op.mean('MAX')
                    })
                    .orderby(desc('MAX_mean'))
                    .slice(0, numMonths)
                    .object()
            }

        } catch (error) {
            console.error(error)
        }

    }

    /* list of coldest months */
    const coldestMonths = (numMonths) => {
        console.log('hottestMonths', numMonths)
        try {
            let temp = dt.value
            if (numMonths > 1) {
                return temp
                    .groupby('MONTH')
                    .rollup({
                        MIN_mean: op.mean('MIN')
                    })
                    .orderby('MIN_mean')
                    .slice(0, numMonths)
                    .objects()
            } else {
                return temp
                    .groupby('MONTH')
                    .rollup({
                        MIN_mean: op.mean('MIN')
                    })
                    .orderby('MIN_mean')
                    .slice(0, numMonths)
                    .object()
            }

        } catch (error) {
            console.error(error)
        }

    }

    /* calculate annual aggregates */
    const annualAggregates = () => {
        console.log('annualAggregates')
        return dt.value
            .groupby('YEAR')
            .rollup({
                PRCP_sum: op.sum('PRCP'),
                MIN_min: op.min('MIN'),
                MAX_max: op.max('MAX')
            })
            .orderby('YEAR')
            .objects()
    }
    /* calculate aggregates by month */
    const monthlyAggregates = () => {
        console.log('monthlyAggregates')
        let ma = dt.value
            .groupby('MONTH')
            .rollup({
                MIN_mean: op.mean('MIN'),
                MAX_mean: op.mean('MAX'),
                TEMP_mean: op.mean('TEMP')
            })
            .objects()

        console.table(ma)
        return ma
    }

    /**
     *  For the selected station returns the hottest day of each year, starting with the most recent
     *  @param {number} numYear - number of years you want returned
     *  */
    const hottestDayOfYear = (numYear) => {
        console.debug('hottestDayOfYear')
        try {
            let temp = dt.value
            return temp
                .orderby(desc('MAX'))
                .dedupe('YEAR')
                .orderby(desc('YEAR'))
                .slice(0, numYear)
                .objects()
        } catch (error) {
            console.error(error)
        }

    }

    /**
     *  For the selected station returns the hottest day of each year, starting with the most recent
     *  @param {number} numYear - number of years you want returned
     *  */
    const wettestDayOfYear = (numYear) => {
        console.debug('wettestDayOfYear')
        try {
            let temp = dt.value
            return temp
                .orderby(desc('PRCP'))
                .dedupe('YEAR')
                .orderby(desc('YEAR'))
                .slice(0, numYear)
                .objects()
        } catch (error) {
            console.error(error)
        }

    }

    /**
     *  For the selected station returns the hottest day of each year, starting with the most recent
     *  @param {number} numYear - number of years you want returned
     *  */
    const coldestDayOfYear = (numYear) => {
        console.debug('coldestDayOfYear')
        try {
            let temp = dt.value
            /* istanbul ignore next */
            return temp
                .filter(d => d.MIN !== null)
                .orderby('MIN')
                .dedupe('YEAR')
                .orderby(desc('YEAR'))
                .slice(0, numYear)
                .objects()
        } catch (error) {
            console.error(error)
        }

    }

    const isLoaded = ref(false)
    const fromDate = ref(null)
    const toDate = ref(null)

    const stationName = ref(null)

    // load the station observation data
    onMounted(async () => {
        console.log('onMounted', stationId)
        const file = '/data/gsod/' + stationId + '.arrow'
        let data = await loadArrow(file)

        /* istanbul ignore next */
        dt.value = data
            .derive({
                YEAR: d => op.year(d.DATE),
                MONTH: d => op.month(d.DATE),
                DAY: d => op.date(d.DATE)
            })


        const observationDates = dt.value
            .rollup({fromDate: op.min('DATE'), toDate: op.max('DATE')})
            .object()

        fromDate.value = observationDates.fromDate
        toDate.value = observationDates.toDate

        stationName.value = dt.value.object(0)['NAME']
        isLoaded.value = true
    })


    return {
        isLoaded,
        fromDate,
        toDate,
        stationName,
        hottestDays,
        hottestMonths,
        hottestDayOfYear,
        coldestDays,
        coldestDayOfYear,
        coldestMonths,
        wettestDays,
        wettestDayOfYear,
        monthlyAggregates,
        annualAggregates,
        monthName,
    }
}