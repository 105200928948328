import {loadArrow, op} from "arquero";
import {ref, onMounted} from '@vue/composition-api'

export default function useGSOD() {
    let dt = ref(null)
    const isLoaded = ref(false)

    // load the station observation data
    onMounted(async () => {
        dt.value = await loadArrow('/data/stations/stations.arrow', {})
        isLoaded.value = true
    })

    const getStationList = (stateCode) => {
        console.log('getStationList', stateCode)
        /* istanbul ignore next */
        return dt.value
            .params({stateCode: stateCode})
            .filter(d => op.equal(d.STATE, stateCode))
            .orderby('STATION NAME')
            .objects()
    }

    const getStateList = () => {
        return dt.value
            .orderby('STATE')
            .dedupe('STATE')
            .select('STATE', 'stateName')
            .objects()
    }

    return {
        isLoaded,
        getStationList,
        getStateList
    }
}