<template>
  <div v-if="isLoaded">
    <v-row>
      <v-col>
        <v-card className="transparent" flat>
          <v-card-title>Cold Records for {{ stationName }}</v-card-title>
          <v-card-subtitle>From: <span data-cy="fromDate">{{ fromDate.toLocaleDateString() }}</span>, To: <span
              data-cy="toDate">{{ toDate.toLocaleDateString() }}</span>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card elevation="2" data-cy="hottestRecord" flat>
          <v-card-title>Coldest Days</v-card-title>
          <v-card-subtitle>The 10 coldest days on record at {{ stationName }} are:</v-card-subtitle>
          <v-card-text>
            <v-simple-table dense>
              <tbody data-cy="coldestDayTable">
              <tr v-for="(row,idx) in coldestDays(10)" :key="idx">
                <td class="text-center">{{ row["DATE"].toLocaleDateString() }}</td>
                <td class="text-center">{{ row.MIN }}&deg;F</td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card elevation="2" data-cy="coldestRecord" flat>
          <v-card-title>Coldest Day by Year</v-card-title>
          <v-card-subtitle>The coldest day by year for the last 10 years are:</v-card-subtitle>
          <v-card-text>
            <v-simple-table dense>
              <tbody data-cy="coldestDayTable">
              <tr v-for="(row,idx) in coldestDayOfYear(10)" :key="idx">
                <td class="text-center">{{ row["DATE"].toLocaleDateString() }}</td>
                <td class="text-center">{{ row.MIN }}&deg;F</td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <!--      <v-col>-->
      <!--        <v-card elevation="2" data-cy="coldestDayChart" flat>-->
      <!--          <v-card-title>-->
      <!--            coldest Day of the Year-->
      <!--          </v-card-title>-->
      <!--          <v-card-text>-->
      <!--            <coldest-day-of-year/>-->

      <!--          </v-card-text>-->
      <!--        </v-card>-->
      <!--      </v-col>-->
    </v-row>

  </div>
</template>

<script>

// import {loadStationGSOD, getHottestDates} from '@/composable/climateDataService'
import useGSOD from '@/composable/useGSOD'
// import hottestDayOfYear from "@/components/charts/hottestDayOfYear";

export default {
  name: "Hottest.vue",
  components: {
    // hottestDayOfYear
  },
  props: {
    stationId: {
      type: String,
    },
  },
  setup(props) {
    const {isLoaded, fromDate, toDate, stationName, coldestDays, coldestDayOfYear} = useGSOD(props.stationId)

    return {
      isLoaded,
      fromDate,
      toDate,
      stationName,
      coldestDays,
      coldestDayOfYear
    }
  }
}
</script>

<style scoped>

</style>