<template>
  <v-row>
    <v-col>
      <v-card elevation="2" cy-data="hottestRecord" flat>
        <v-card-title class="red--text red lighten-5 mb-5">
          <v-icon color="red" class="mr-3">mdi-thermometer-high</v-icon>
          Hottest Day
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cy-data="hottestTemp" class="display-1 text-center red--text">{{ hottestDays.MAX }}&deg;F</v-col>
          </v-row>
          <v-row>
            <v-col cy-data="hottestDate" class="caption">The hottest recorded day is
              {{
                hottestDays["DATE"].toLocaleDateString('en-US', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })
              }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="text-right">
          <v-spacer/>
          <v-btn data-cy="btnHeat" :to="{name: 'Hottest'}" class="red--text push" text>More heat records</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col>
      <v-card elevation="2" cy-data="coldestRecord">
        <v-card-title class="blue--text mb-5 blue lighten-5">
          <v-icon color="blue" class="mr-3">mdi-thermometer-low</v-icon>
          Coldest Day
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cy-data="coldestTemp" class="display-1 text-center blue--text text-darken-5">{{ coldestDays.MIN }}&deg;F</v-col>
          </v-row>
          <v-row>
            <v-col cy-data="coldestDate" class="caption">The coldest day on record is
              {{
                coldestDays["DATE"].toLocaleDateString('en-US', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })
              }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :to="{name: 'Coldest'}" class="blue--text">More Cold Records</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col>
      <v-card elevation="2" cy-data="wettestRecord">
        <v-card-title class="blue-grey--text blue-grey lighten-5 mb-5">
          <v-icon color="blue-grey" class="mr-5">mdi-weather-rainy</v-icon>
          Wettest Day
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cy-data="wettestDepth" class="display-1 text-center blue-grey--text">{{ wettestDays.PRCP }} inches
            </v-col>
          </v-row>
          <v-row>
            <v-col cy-data="wettestDate" class="caption">The wettest day recorded is
              {{
                wettestDays["DATE"].toLocaleDateString('en-US', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })
              }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text :to="{name: 'Precipitation'}" class="blue-grey--text">More Precipitation Records</v-btn>
        </v-card-actions>

      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'recordCards',
  props: {
    coldestDays: {},
    hottestDays: {},
    wettestDays: {}
  }
}
</script>
