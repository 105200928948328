import {toRefs, reactive, onMounted} from '@vue/composition-api'
import {loadArrow, op} from "arquero";


export default function useStations() {
    const state = reactive({
        stations: [],
        loaded: false
    })

    onMounted(async () => {
        await loadStations()
    })


    const loadStations = async () => {
        console.log('loadStations')
        try {
            state.stations = await loadArrow('/data/stations/stations.arrow', {})
        } catch (e) {
            console.log(e)
        } finally {
            state.loaded = true
        }
    }

    const stationList = (stateCode) => {
        console.log('getStationList', stateCode)

        /* istanbul ignore next */
        return state.stations
            .params({stateCode: stateCode})
            .filter(d => op.equal(d.STATE, stateCode))
            .orderby('STATION NAME')
            .objects()
    }

    const stateList = () => {
        console.log('stateList')
        return state.stations
            .orderby('STATE')
            .dedupe('STATE')
            .select('STATE', 'stateName')
            .objects()
    }

    const stationDetails = (stationId) => {
        console.log('stationDetails', stationId)
        /* istanbul ignore next */
        return state.stations
            .params({stationId: stationId})
            .filter(d => op.equal(d.stationId, stationId))
            .object()

    }

    return {...toRefs(state), loadStations, stationList, stateList, stationDetails}
}