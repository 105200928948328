<template>
  <div class="about">
    <h1>This is an about page</h1>

    <div v-if="loaded">
      <!--    {{ stateList() }}-->
      <!--      {{ // stationList('VA')}}-->
      {{ stationDetails('72401693736') }}
    </div>
  </div>
</template>

<script>
import useStations from '@/composition/useStations'
// import { onMounted} from "@vue/composition-api";

export default {
  setup() {
    const {stationDetails, stationList, stateList, loaded} = useStations()

    return {
      stationDetails, stationList, stateList, loaded
    }
  }
}
</script>